<template>
<div class="tw-flex tw-items-center tw-justify-center" style="width: 32px; height: 32px;background-color: #8F47F4;">
<label class="avatar">{{avatar}}</label>
</div>
</template>

<script>
export default {
  name: "DefaultAvatar",
  props:{
    companyName : [String]
  },
  computed:{
    avatar(){
      return this.companyName.charAt(0).toUpperCase()
    }
  }
}
</script>

<style scoped>
.avatar{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #FFFFFF;
}
</style>
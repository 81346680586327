<template>
<div class="tw-flex card tw-flex-col tw-p-5 lg:tw-mr-5 tw-mt-5 tw-cursor-pointer"
     @click="handleRouteTo">
  <div class="tw-flex tw-w-full tw-justify-between tw-items-start ">
    <img v-if="data.company_logo.startsWith('https')" :src="data.company_logo" style="width: 32px; height: 32px;"/>
    <default-avatar v-else :company-name="data.company_name" />
    <small class="card-date" v-if="!saved">{{processedDay(data.date_applied)}}</small>
  </div>
  <h6 class="card-title tw-mt-10">{{data.title}}</h6>
  <small class="card-company text-capitalize">{{data.company_name}} • {{data.type}}</small>
  <div class="tw-flex tw-w-full tw-justify-between tw-mt-5" v-if="saved">
    <small class="job-type tw-items-center tw-flex">
      <vue-feather class="tw-mr-1" type="map-pin" style="width: 18px; height: 18px;" />
      <label v-if="data.is_remote">Remote</label>
      <label class="text-capitalize" v-if="!data.is_remote">{{data.city+' '+data.state+', '+data.country}}</label>
    </small>
    <small class="job-type" v-if="data.date_published">Posted {{processedDay(data.date_published)}}</small>
  </div>
</div>
</template>

<script>
import defaultLogo from "@/assets/images/enumlogos.svg"
import DefaultAvatar from "../../reusables/DefaultAvatar";
export default {
  name: "JobCard",
  components: {DefaultAvatar},
  props:{
   data: Object,
    saved : Boolean
  },
  data(){
    return{
      defaultLogo: defaultLogo
    }
  },

  methods:{
    handleRouteTo(){
      if (!this.saved){
        this.$router.push({name:'AppliedJobDetails',params:{job_id:this.data.id}, query:{'company_name':this.data.company_name,'job_title':this.data.title}})
      }
      else {
        this.$router.push({name:'SearchResult',query:{jobId:this.data.id}})
      }

    },
    processedDay(date_created) {
      let day;
      let date, now
      day = date_created.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " " + "ago"
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " " + "ago"
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " " + "ago"
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " + "ago" ;
      return "Created Today";

      // return day;

    }
  }
}
</script>

<style scoped lang="scss">
.card{
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  width: 100%;
  height: 200px;
  @media screen  and (min-width: 1023px){
    width: 382.67px;
  }
  @media screen and (width: 1024px){
    width: 46%;
  }

}
.card-date{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;
}
.card-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;
}
.card-company{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.job-type{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.01em;
  color: #475661;
}
</style>
<template>
<div class="tw-flex tw-w-full">
  <loader v-if="loading" :loading="loading" />
  <div v-if="!loading && appliedJobs.length > 0" class="tw-flex tw-w-full  tw-flex-wrap tw-pb-14 tw-pt-8">
    <job-card v-for="(job, index) in appliedJobs" :key="index" :data="job"/>
  </div>
  <small class="yet tw-flex tw-w-full tw-pb-14 tw-pt-8"
         v-if="!loading && appliedJobs.length === 0">You have not applied for any job yet!</small>
</div>
</template>

<script>
import JobCard from "../reuseables/JobCard";
import Loader from "../../../Loader";
import {getAllAppliedJobs} from "../../../services/api/APIService";
export default {
  name: "AppliedJobs",
  components: {Loader, JobCard},
  data(){
    return{
      loading : false,
      appliedJobs:[]
    }
  },
  methods:{
    getAppliedJobs(){
      this.loading = true
      const userData =  JSON.parse(sessionStorage.getItem('userData'))
      if (userData){
        getAllAppliedJobs(userData.email).then(res =>{
          this.appliedJobs = res.data
          this.loading = false
        })
            .catch(()=>{
              this.loading = false
            })
      }
    }
  },
  created() {
    this.getAppliedJobs()
  }
}
</script>

<style scoped>
.yet{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;

  color: #9CABB5;
}
</style>